import React from "react";
import homeImage from "../Images/home-img.png";
import "./Home.css";
import Header from "../Header/Header";

const Home = () => {
  return (
    <div className="home-page-wrapper">
      <Header />
      <section id="home" className="home">
        <div className="container d-flex h-100 home-top-section">
          <div className="home-text">
            <span>Better Solutions For Your Business</span>
          </div>
          <div className="home-image-wrapper">
            <img src={homeImage} alt="home" />
          </div>
        </div>
      </section>
      <div className="home-page-content-wrapper">
        <div className="home-page-content-container">
          <div className="home-page-content-header">
            <span>DEVELOPMENT</span>
          </div>
          <div className="home-page-content-content">
            <span>In an ever-changing world, staying ahead of the competition is now harder than ever before. Optimization of business processes and performance is a continuous necessity if we are to improve customer engagement, realize increased efficiency and ultimately generate increased turnover and profit. To achieve this, organizations must implement software solutions that are specifically designed and developed to their unique business processes. We offer leading-edge technical knowledge and proven experience, taking a consultative approach to understanding your needs in order to support you in choosing the most suitable development approach.</span>
          </div>
        </div>
        <div className="home-page-content-container">
        <div className="home-page-content-header">
            <span>STAFFING</span>
          </div>
          <div className="home-page-content-content">
            <span>
            For a contract employment agency to provide its clients the best service possible, it must understand the clients’ needs.
            Our approach provides your business with dedicated staffing consultants. The result is increased responsiveness, as well as accountability, because the consultant who creates your staffing solution is also responsible for implementing it. Highly trained and qualified, our agents are experts in screening talent and assessing proficiency. We also conduct focused behavioral interviews and comprehensive skill assessments to ensure you get the exact staffing solution you need.
            When Infiwhiz Software evaluates a company’s need for contract staffing, we explore the potential for a healthy, productive relationship based on your corporate values.
            </span>
          </div>
        </div>
        <div className="home-page-content-container">
        <div className="home-page-content-header">
            <span>PROJECT MANAGEMENT</span>
          </div>
          <div className="home-page-content-content">
            <span>We believe that having a structured approach to project management is key to the successful delivery of any project. Infiwhiz Software hold an extensive body of knowledge and experience in managing IT projects. Our methodology is based on a collaborative approach with our clients. From the smallest software development project to the implementation of complex programmes, Claisys can provide top quality project management skills to its clients. The success stories of our clients in the outcome of their projects are our UNIT OF MEASURE OF SUCCESS.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

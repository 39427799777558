import React from "react";
import ServiceBox from "./ServiceBox";
import "./Services.css";
import Header from "../Header/Header";

const Services = () => {
  return (
    <div className="services-wrapper">
      <div className="services-top-section"></div>
      <Header />
      <section id="services" className="services">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-title">
            <h2>Services</h2>
          </div>

          <div className="services-box-container">
            <ServiceBox
              service={{
                headerText: "CLOUD COMPUTING STAFFING SERVICES",
                contentText:
                  "Major corporations and smaller businesses across all industries are searching for ways to implement and integrate Cloud technology. Infiwhiz Software has multiple Cloud computing....",
                path: "/services/cloud-computing"
              }}
            />
            <ServiceBox
              service={{
                headerText: "IT ENGINEERING SERVICES INDUSTRIES KNOWLEDGE",
                contentText:
                  "Infiwhiz Software has been learning the IT staffing needs of a wide variety of industries, including engineering industries. Some of the biggest companies....",
                path: "/services/engineering-services"
              }}
            />
            <ServiceBox
              service={{
                headerText: "VENDOR ON PREMISE STAFFING SERVICES",
                contentText:
                  "A vendor on premise service is the next step for many businesses that have been consistently using a staffing agency. A VOP will work on-site at your office, warehouse, or other location....",
                  path: "/services/vendor-staffing"
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;

import React from "react";
import "./AboutUs.css";
import Header from "../Header/Header";

const AboutUs = () => {
  return (
    <div className="about-us-wrapper">
      <Header />
      <div className="about-top-section">
      </div>
      <section id="about" className="about">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
          </div>
          <div className="row content">
            <p>Incorporated in 2003, Infiwhiz Software provides a variety of services related to Information Technology and out sourcing and has been recognized as an one of the emerging leader in software solutions in eastern part of USA and southern part of India.</p>
            <p>The growth of Infiwhiz Software is directly related to Infiwhiz Software’s recruiting and retention of top-notch software professionals for both engineering and corporate IT customers. Our unique methods and best-case practices raise us above all others and as a result, our client base has been continuously growing.</p>
            <p>Infiwhiz Software is doing business in many states with cutting edge technology companies. Infiwhiz Software has accomplished, caring and efficient management and is serving the needs of various domain of companies such as Telecom, Finance, Healthcare, Manufacturing, Education and Government etc. Our consultants bring considerable domain expertise in process modeling, content management, performance attribution, financial modeling and enterprise resource planning. We combine this with in depth technical skills in Database, Web and Enterprise technologies to provide a result oriented and cost effective consulting solution.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;

import React from "react";
import { useHistory } from "react-router-dom";

const ServiceBox = (props) => {
  const history = useHistory();
  const { service } = props;
  console.log("service :>> ", service);
  return (
    <div className="service-box" onClick={() => {
      history.push(service.path);
    }}>
      <div className="service-box-header">
        <span>{service.headerText}</span>
      </div>
      <div className="service-box-content">
        <span>{service.contentText}</span>
      </div>
    </div>
  );
};

export default ServiceBox;

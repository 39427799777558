import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Home/Home";
import AboutUs from "../AboutUs/AboutUs";
import Services from "../Services/Services";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import CloudComputing from "../CloudComputing/CloudComputing";
import EngineeringServices from "../EngineeringServices/EngineeringServices";
import VendorStaffing from "../VendorStaffing/VendorStaffing";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/services/cloud-computing" component={CloudComputing} />
          <Route exact path="/services/engineering-services" component={EngineeringServices} />
          <Route exact path="/services/vendor-staffing" component={VendorStaffing} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import "./Header.css";

const Header = () => {
  const history = useHistory();
  const { pathname } = history.location;
  const path = pathname.replace("/", "");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirect = (path) => {
    handleClose();
    history.push(`/${path}`)
  }

  return (
    <header id="header" className="fixed-top header-scrolled">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <a href="/">Infiwhiz Software</a>
        </h1>
        <nav id="navbar" className="navbar desktop">
          <ul>
            <li>
              <span className={`nav-link scrollto ${path === "" ? "active" : ""}`} onClick={() => redirect("")}>Home</span>
            </li>
            <li>
              <span className={`nav-link scrollto ${path === "about" ? "active" : ""}`} onClick={() => redirect("about")}>About</span>
            </li>
            <li>
              <span className={`nav-link scrollto ${path === "services" ? "active" : ""}`} onClick={() => redirect("services")}>Services</span>
            </li>
            <li>
              <span className={`nav-link scrollto ${path === "contact" ? "active" : ""}`} onClick={() => redirect("contact")}>Contact</span>
            </li>
          </ul>
        </nav>


        <div className="menu-mobile">
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            {/* Open Menu */}
            <MenuIcon style={{ color: "#ffffff" }}/>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => redirect("")}>Home</MenuItem>
            <MenuItem onClick={() => redirect("about")}>About</MenuItem>
            <MenuItem onClick={() => redirect("services")}>Services</MenuItem>
            <MenuItem onClick={() => redirect("contact")}>Contact</MenuItem>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
